import React from 'react'

const Profile = () => {
    return (
        <div>
            <h1 className="title">Profile</h1>
            <section className="trails-card">
            </section>
        </div>
    )
}

export default Profile